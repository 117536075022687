/* eslint-disable */
/* tslint:disable */
/*
 * ---------------------------------------------------------------
 * ## THIS FILE WAS GENERATED VIA SWAGGER-TYPESCRIPT-API        ##
 * ##                                                           ##
 * ## AUTHOR: acacode                                           ##
 * ## SOURCE: https://github.com/acacode/swagger-typescript-api ##
 * ---------------------------------------------------------------
 */

import { MapAssetStorageDetails, MapCreate, MapDetails, MapUpdate, Operation } from './data-contracts'
import { ContentType, HttpClient, RequestParams } from './http-client'

export class Map<SecurityDataType = unknown> extends HttpClient<SecurityDataType> {
    /**
     * No description
     *
     * @tags Map
     * @name GetMap
     * @summary [🤵 OR 🔑] Get a map by id (string). [🔒: map_read].
     * @request GET:/map/{mapId}
     * @secure
     */
    getMap = (mapId: string, params: RequestParams = {}) =>
        this.request<MapDetails, void>({
            path: `/map/${mapId}`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Map
     * @name PutMap
     * @summary [🤵 OR 🔑] Update a map by short id (int). [🔒: map_update].
     * @request PUT:/map/{mapId}
     * @secure
     */
    putMap = (mapId: string, data: MapUpdate, params: RequestParams = {}) =>
        this.request<MapDetails, void>({
            path: `/map/${mapId}`,
            method: 'PUT',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Map
     * @name PatchMap
     * @summary [🤵 OR 🔑] Patch a map by short id. [🔒: map_update].
     * @request PATCH:/map/{mapId}
     * @secure
     */
    patchMap = (mapId: string, data: Operation[], params: RequestParams = {}) =>
        this.request<MapDetails, void>({
            path: `/map/${mapId}`,
            method: 'PATCH',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Map
     * @name DeleteMap
     * @summary [🤵 OR 🔑] [🔒: map_delete].
     * @request DELETE:/map/{mapId}
     * @secure
     */
    deleteMap = (mapId: string, params: RequestParams = {}) =>
        this.request<void, void>({
            path: `/map/${mapId}`,
            method: 'DELETE',
            secure: true,
            ...params
        })
    /**
     * No description
     *
     * @tags Map
     * @name PermissionsList
     * @summary [🤵 OR 🔑] Get permissions for a map.
     * @request GET:/map/{mapId}/permissions
     * @secure
     */
    permissionsList = (mapId: string, params: RequestParams = {}) =>
        this.request<
            {
                map_create?: boolean
                map_update?: boolean
                map_delete?: boolean
                map_read?: boolean
                metadata_create?: boolean
                metadata_update?: boolean
                metadata_delete?: boolean
                metadata_read?: boolean
                token_create?: boolean
                token_update?: boolean
                token_delete?: boolean
                token_read?: boolean
                user_create?: boolean
                user_update?: boolean
                user_delete?: boolean
                user_read?: boolean
                statistics_read?: boolean
                storage_file_list?: boolean
                storage_file_create?: boolean
                storage_file_update?: boolean
                storage_file_delete?: boolean
                picking_optimization_create?: boolean
                picking_optimization_read?: boolean
                picking_optimization_run?: boolean
                audit_list?: boolean
                audit_edit?: boolean
                audit_heatmap_read?: boolean
                batching_raw_file_list?: boolean
                batching_raw_file_create?: boolean
                batching_raw_file_update?: boolean
                batching_raw_file_delete?: boolean
            },
            void
        >({
            path: `/map/${mapId}/permissions`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Map
     * @name AssetStorageList
     * @summary [🤵 OR 🔑] Get url where to find addressable asset for current map. [🔒: map_read].
     * @request GET:/map/{mapId}/asset/storage
     * @secure
     */
    assetStorageList = (mapId: string, params: RequestParams = {}) =>
        this.request<MapAssetStorageDetails, void>({
            path: `/map/${mapId}/asset/storage`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Map
     * @name MapsList
     * @summary [🤵 OR 🔑] Get all maps for current client. [🔒: map_read].
     * @request GET:/maps
     * @secure
     */
    mapsList = (params: RequestParams = {}) =>
        this.request<MapDetails[], void>({
            path: `/maps`,
            method: 'GET',
            secure: true,
            format: 'json',
            ...params
        })
    /**
     * No description
     *
     * @tags Map
     * @name PostMap
     * @summary [🤵 OR 🔑] Create new map. [🔒: map_create].
     * @request POST:/map
     * @secure
     */
    postMap = (data: MapCreate, params: RequestParams = {}) =>
        this.request<MapDetails, void>({
            path: `/map`,
            method: 'POST',
            body: data,
            secure: true,
            type: ContentType.Json,
            format: 'json',
            ...params
        })
}
