import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import currentUserIsAdmin from 'backend/utils/currentUserIsAdmin';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'store/store'
import nameOf from 'utility/nameOf';
import checkPermissions from 'utility/checkPermissions';
import { PermissionsEnum } from 'backend/ApiBackofficeDefinition/data-contracts';
import { loadBatchingRawFileThrunk } from 'store/reducers/MiddlewarePicking/thrunks/loadBatchingRawFileThrunk';
import TableWithCrudComponent from 'components/Admin/TableWithCrudComponent';
import { TableColumn } from 'react-data-table-component';
import { BatchingProcessStateEnum, BatchingRawFileCreate, BatchingRawFileDetails, DownloadFileType } from 'backend/ApiMiddlewareDefinition/data-contracts';
import dayjs from 'dayjs';
import { Badge, Button, Modal, Offcanvas } from 'react-bootstrap';
import BatchingUploadRawFileForm, { BatchingRawFileFormInputs } from 'components/Middleware/Batching/BatchingUploadRawFileForm';
import { FormikHelpers } from 'formik';
import parseApiErrorSetFieldError from 'utility/parseApiErrorSetFieldError';
import { toast } from 'react-toastify';
import { createAndUploadBatchingRawFileThunk } from 'store/reducers/MiddlewarePicking/thrunks/createAndUploadBatchingRawFileThunk';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { downloadFile } from 'utility/downloadFile';
import { downloadBatchingRawFileThunk } from 'store/reducers/MiddlewarePicking/thrunks/downloadBatchingRawFileThunk';
import Loading from 'components/Loading';
import DeleteConfirmForm from 'components/DeleteConfirmForm';
import parseApiErrorToast from 'utility/parseApiErrorToast';
import { deleteBatchingRawFileThunk } from 'store/reducers/MiddlewarePicking/thrunks/deleteBatchingRawFileThunk';
import { runBatchingForRawFileThunk } from 'store/reducers/MiddlewarePicking/thrunks/runBatchingForRawFileThunk';
import ButtonFno from 'components/inputs/ButtonFno';
import LogsListComponent from 'components/Middleware/LogsListComponent';
import "./BatchingDashboard.scss"
import useSignalRHub from 'hooks/useSignalRHub';
import getCurrentUserToken from 'backend/utils/getCurrentUserToken';
import _ from 'lodash';

const BatchingDashboard: React.FC = () => {
    const { t } = useTranslation(nameOf({BatchingDashboard}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(state => state.userProfil.currentUser);
    const currentMap = useAppSelector(state => state.map.currentMap);
    const allMaps = useAppSelector(state => state.map.maps);
    const tenantSelected = useAppSelector(state => state.userProfil.currentTenant);
    const [searchTerm, setSearchTerm] = useState('');
    const [rowPerPage, setRowPerPage] = useState(20);
    const [showCreateModal, setShowCreateModal] = useState(false);
    const [realtimeConnected, setRealtimeConnected] = useState(false);
    const [selectedEntityToDelete, setSelectedEntityToDelete] = useState<BatchingRawFileDetails>();
    const [selectedEntityToShowDetails, setSelectedEntityToShowDetails] = useState<BatchingRawFileDetails>();
    const {
        middlewareSelected,
        globalStatisticsByDay,
        loadingList,
        loadingForm,
        statsDateFrom,
        statsDateTo,
        batchingFiles
    } = useAppSelector(state => state.middlewarePicking);

    const haveEditPermissionCreate = checkPermissions(PermissionsEnum.BatchingRawFileCreate);
    const userIsAdmin = currentUserIsAdmin();


    const adminHub = useSignalRHub(process.env.REACT_APP_API_PICKING_URL + "/live/picking/middleware", {
        onConnected: (hub) => {
            setRealtimeConnected(true);
            hub.on("BatchingFileOptimized", (fileId: string) => {
                console.log("BatchingFileOptimized", fileId); 
                loadBatchingFiles(batchingFiles?.currentPage ?? 1, false);
            }) 
        },

        onDisconnected: (err) => {
            setRealtimeConnected(false);
        },
        onError: (err) => {
            console.log("onError !", err); 

        },
        enabled: true,
        automaticReconnect: true,
        httpTransportTypeOrOptions: {accessTokenFactory: getCurrentUserToken}
      })




    useEffect(() => {
        document.title = t("Fichiers de regroupement");
    }, [])

    useEffect(() => {
        loadBatchingFiles(batchingFiles?.currentPage ?? 1);
    }, [rowPerPage, currentMap, middlewareSelected, statsDateFrom, statsDateTo])

    const loadBatchingFiles = (page: number, setLoading = true) => {
        if(middlewareSelected && currentMap){
            dispatch(loadBatchingRawFileThrunk(middlewareSelected?.baseUrl, currentMap?.id, page, rowPerPage, setLoading));
        } 
    }

    const onCreateEntity = async (values: BatchingRawFileFormInputs, {setFieldError}:FormikHelpers<BatchingRawFileFormInputs>) => {
        return new Promise<void>((resolve, reject) => {

            const modelToCreate = values as unknown as BatchingRawFileCreate;
            modelToCreate.author = currentUser?.email ?? '';

            dispatch(createAndUploadBatchingRawFileThunk(
                    middlewareSelected?.baseUrl ?? "",
                    middlewareSelected?.clientTenant?? "",
                    currentMap?.id ?? "", 
                    modelToCreate, 
                    values.dataCsvFile, {
                onSuccess: (file) => {
                    if(!values.createAnother) 
                    {
                        setShowCreateModal(false);
                    }
                    

                    // force first page reload for see new created file
                    loadBatchingFiles(1);

                    setTimeout(() =>{
                        // run optimization process if creation ok
                        runBatchtionOptimization(file);
                    }, 300)

                    resolve();     
                },
                onError: (error) => {
                    parseApiErrorSetFieldError(error, setFieldError);
                    reject();
                }
            }));
         });
    }

    const onDeleteEntity = (file: BatchingRawFileDetails) => {

        dispatch(deleteBatchingRawFileThunk(
            middlewareSelected?.baseUrl ?? "",
            middlewareSelected?.clientTenant?? "",
            file.mapId, 
            file.id, {
            onSuccess: (success) => {
                toast.success(t('Le fichier a bien été supprimée'));
                setSelectedEntityToDelete(undefined);
                loadBatchingFiles(batchingFiles?.currentPage ?? 1);
                        
            },
            onError: (error) => {
                parseApiErrorToast(error);
            }
        }));
    }

    const runBatchtionOptimization = (file: BatchingRawFileDetails) => {

        dispatch(runBatchingForRawFileThunk(
            middlewareSelected?.baseUrl ?? "",
            middlewareSelected?.clientTenant?? "",
            file.mapId, 
            file.id, {
            onSuccess: (success) => {
                toast.success(t("L'optimization du fichier est en cours"));
                        
            },
            onError: (error) => {
                parseApiErrorToast(error);
            }
        }));
    }


    const onDownload = (batchingFile: BatchingRawFileDetails, fileType: DownloadFileType) => {

        const toastId = toast.info(<div>
            {t("Génération du lien de téléchargement")}
            <div className="text-center"><Loading inline size={30} /></div>
        </div>, { autoClose: false });

        dispatch(downloadBatchingRawFileThunk(
            middlewareSelected?.baseUrl ?? "",
            middlewareSelected?.clientTenant?? "",
            batchingFile.mapId,
            batchingFile.id,
            fileType,
            {onSuccess: (result) => {
                let fileName = batchingFile.name.endsWith(".csv") ? batchingFile.name : batchingFile.name + ".csv";

                if(fileType == DownloadFileType.Final)
                    fileName = "(optimized)-" + fileName;

                downloadFile(result.downloadUrl ?? "", fileName, false)
                toast.update(toastId, { render: <div>
                    <FontAwesomeIcon size="lg" icon={["fas", "check"]} /> {t("Fichier téléchargé")}
                </div>, autoClose: 5000, type: "success" });
            }, onError: (errors) => {
                toast.update(toastId, { render: <div>
                    {t("Une erreur est survenue lors de la génération du lien de téléchargement")}
                </div>, autoClose: 5000, type: "error" });
            }}
        ));
    }

    const columns: TableColumn<BatchingRawFileDetails>[] = [
        {
            id:'name',
            name: t('name'),
            sortable: false,
            grow: 2,
            selector: row => row.name,
            cell:(row) => <>
                
                <Button size='sm' variant='link' title={t('Télécharger le fichier source CSV')} className='p-0' onClick={() => onDownload(row, DownloadFileType.Original)}>
                    {row.name}
                </Button>
            </>
        },
        {
            id:'totalRows',
            name: t('Nb lignes'),
            sortable: false,
            compact: true,
            selector: row => row.totalRows,
        },
        {
            id:'state',
            name: t('Status'),
            cell: row => {
                return <>
                {!row.state && <><Badge bg="secondary">{t('?')}</Badge></>}
                {row.state == BatchingProcessStateEnum.Error && <><Badge bg="danger"> {t('Erreur')}</Badge></>}
                {row.state == BatchingProcessStateEnum.None && <><Badge bg="info">{t('N/C')}</Badge></>}
                {row.state == BatchingProcessStateEnum.Waiting && <><Badge bg="info"><FontAwesomeIcon icon={["fas", "hourglass"]} /> {t("En attente d'optimisation")}</Badge></>}
                {row.state == BatchingProcessStateEnum.Processed && <><Badge bg="success"><FontAwesomeIcon icon={["fas", "check"]} /> {t('Terminé')}</Badge></>}
                {row.state == BatchingProcessStateEnum.Processing && <><Badge bg="primary">{t("En cours d'optimization")}</Badge></>}
            </>
            },
        },
        {
            id:'result',
            name: t('Résultat'),
            cell: row => {

                const showDownloadResultBtn = (row.state == BatchingProcessStateEnum.Processed);
                const showRunOptimizationBtn = currentUserIsAdmin() || (row.state == BatchingProcessStateEnum.Waiting || row.state == BatchingProcessStateEnum.None || row.state == BatchingProcessStateEnum.Error);
                const showLoading = (row.state == BatchingProcessStateEnum.Processing);

                return <>
                {showDownloadResultBtn && <Button size='sm' variant={row.state != BatchingProcessStateEnum.Processed ? 'secondary' : 'success'} disabled={row.state != BatchingProcessStateEnum.Processed} 
                        title={t('Télécharger le fichier optimisé')} className='ms-1' onClick={() => onDownload(row, DownloadFileType.Final)}>
                        <FontAwesomeIcon icon={["fas", "download"]} />
                </Button>}

                {showLoading && <span className='ms-1'><Loading inline size={30} color='#0d6efd' /></span>}

                {showRunOptimizationBtn && 
                     <Button size='sm' variant='success' title={t("Lancer le calcul d'optimisation")} className='ms-1' onClick={() => runBatchtionOptimization(row)}>
                        <FontAwesomeIcon icon={["fas", "play"]} />
                    </Button>}

            </>
            },
        },
        {
            id:'author',
            name: t('Auteur'),
            sortable: false,
            selector: row => row.author
        },
        {
            id:'dateCreated',
            name: t('Créé le'),
            sortable: false,
            selector: row => `${row.dateCreated ? dayjs(row.dateCreated).unix(): ""}`,
            cell: row => `${row.dateCreated ? dayjs(row.dateCreated).format("DD/MM/YYYY HH:mm"): ""}`
        },
        {
            name:<div>{t('Actions')}</div>,
            center:true,
            cell: (row, index, column, id) =>{

                return <div>
                    {userIsAdmin && row.logs.length > 0 && <Button size='sm' variant='primary' title={t("Voir les logs d'exécution")} className='ms-1' onClick={() => setSelectedEntityToShowDetails(row)}><FontAwesomeIcon icon={["fas", "list"]} /></Button>}
                    {checkPermissions(PermissionsEnum.BatchingRawFileDelete) && <Button size='sm' variant='danger' className='ms-1' onClick={() => setSelectedEntityToDelete(row)}>
                        <FontAwesomeIcon icon={["fas", "trash"]} />
                    </Button>}
                </div>
            }
        }
    ];

    const entities = batchingFiles?.items ?? [];

    const newestBatchingFile = entities.length > 0 ? entities[0] : undefined;

    return (
        <div>
             <PageHeader title={t("Liste des données pour le batching")}/>
            <div>
            <TableWithCrudComponent 
                entities={entities} 
                columns={columns}
                loadingForm={loadingForm}
                loadingList={loadingList}
                addDefaultActionsColumn={false}
                hideIdColumn={true}
                defaultSortFieldId='dateCreated'
                defaultSortFieldDirection='desc'
                onClickCreateButton={haveEditPermissionCreate ? () => setShowCreateModal(true) : undefined}
                translations={{
                    tableTitle: t("Fichiers batching"),
                    noEntityText: t("Aucun fichier disponible"),
                    createButtonText: t("Importer un CSV"),
                    deleteText: (entity) => (t("Voulez-vous supprimer le fichier ?")),
                    deleteTitle: (entity) => (t("Supprimer le fichier  ") + entity.name),
                }}
                fieldSearchable={(entity) => [
                    entity.id,
                    entity.author,
                    entity.name
                ]}
                customHeader={<>
                    <div className='d-flex align-items-center me-2'> 

                        <span className={"me-1 " + (realtimeConnected ? "text-success" : "text-danger")} title={realtimeConnected ? t("Connecté") : t("Hors ligne")}><FontAwesomeIcon icon={["fas", "wifi"]} /></span>
                        <ButtonFno loading={loadingList} color='blue' onClick={() => loadBatchingFiles(batchingFiles?.currentPage ?? 1)}>{!loadingList && <FontAwesomeIcon icon={["fas", "refresh"]}/>}</ButtonFno>
                    </div>
                </>}
            />
            </div>

            {showCreateModal && <Modal dialogClassName='modal-fno' show={true} onHide={() => setShowCreateModal(false)}>
               <Modal.Header closeButton>
                   <Modal.Title>{t("Importer un CSV")}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                   <BatchingUploadRawFileForm onCancel={() => setShowCreateModal(false)} 
                                           initialValues={{
                                            mapId: currentMap?.id ?? "",
                                            author: currentUser?.email,
                                            columnsBindingData: newestBatchingFile ? newestBatchingFile.columnsBindingData : {}
                                        } as BatchingRawFileDetails}
                                           allMaps={allMaps}
                                           onSubmit={onCreateEntity} 
                                           loading={loadingForm}/>
               </Modal.Body>
           </Modal>}

           {selectedEntityToDelete && <Modal dialogClassName='modal-fno' show={true} onHide={() => setSelectedEntityToDelete(undefined)}>
               <Modal.Header closeButton>
                   <Modal.Title>{t("Supprimer le fichier") + " " + selectedEntityToDelete.name}</Modal.Title>
               </Modal.Header>
               <Modal.Body>
                   <DeleteConfirmForm onCancel={() => setSelectedEntityToDelete(undefined)} 
                                       onSubmit={() => onDeleteEntity(selectedEntityToDelete)} 
                                       text={t("Êtes vous sûre de vouloir supprimer ce fichier ?")}
                                       loading={loadingForm}/>
               </Modal.Body>
           </Modal>}

           <Offcanvas className="offcanvas-batching-details" show={!!selectedEntityToShowDetails} onHide={() => setSelectedEntityToShowDetails(undefined)} >
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>{selectedEntityToShowDetails?.name}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <LogsListComponent logs={selectedEntityToShowDetails?.logs ?? []} />
                </Offcanvas.Body>
        </Offcanvas>
        </div>
    )
}

export default BatchingDashboard
