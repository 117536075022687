import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn } from 'react-data-table-component';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import Loading from 'components/Loading';
import nameOf from 'utility/nameOf';
import _ from 'lodash';
import { Badge, Button, Col, Form, Modal, Row } from 'react-bootstrap';
import EditJsonRaw from 'components/Admin/EditJsonRaw';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'store/store';
import { ROUTES } from 'resources/routes-constants';
import { loadRawDataThrunk } from 'store/reducers/MiddlewarePicking/thrunks/loadRawDataThrunk';
import { RequestRawProductDetails } from 'backend/ApiMiddlewareDefinition/data-contracts';
import dayjs from 'dayjs';


const MiddlewareRawData: React.FC = () => {
    const { t } = useTranslation(nameOf({MiddlewareRawData}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const [selectedRow, setSelectedRow] = useState<RequestRawProductDetails>();
    const [headers, setHeaders] = useState< TableColumn<RequestRawProductDetails>[]>([]);
    const navigate = useNavigate();
    const [searchTerm, setSearchTerm] = useState('');

    const [rowPerPage, setRowPerPage] = useState(20);

    const {
        middlewareSelected,
        globalStatisticsByDay,
        loadingList: loading,
        statsDateFrom,
        statsDateTo,
        rawProducts
    } = useAppSelector(state => state.middlewarePicking);

    const currentMap = useAppSelector(state => state.map.currentMap);
    const tenant = useAppSelector(state => state.userProfil.currentTenant);



    useEffect(() => {
        document.title = t("Données produits");
    }, [])


    useEffect(() => {
        if(!middlewareSelected)
        {
            navigate(ROUTES.DASHBOARD)
        }
    }, [middlewareSelected])


    
    useEffect(() => {
        const timer = setTimeout(() => {
                loadRawProducts(1)
            }, 1000)
            
            return () => clearTimeout(timer)
    }, [searchTerm])

    useEffect(() => {
        if(rawProducts && rawProducts.items.length > 0)
        {
            const headersData: TableColumn<RequestRawProductDetails>[] = []

            const firstRow = rawProducts.items[0].rawData;

            const allHeaders = _.keys(firstRow)



            for (let index = 0; index < allHeaders.length; index++) {
                const col = allHeaders[index];
                if(col)
                {
                    headersData.push({
                        id:col,
                        name: col,
                        sortable: false,
                        selector: row => row.rawData[col]?.toString()
                    })
                }
            }

            headersData.push({
                id:"date",
                name: "Date",
                sortable: false,
                selector: row => dayjs(row.dateRequestClient).format("DD/MM/YYYY HH:mm:ss")
            })


            setHeaders(headersData);
        }
        else{
            setHeaders([]);
        }
    }, [rawProducts])


    useEffect(() => {
        loadRawProducts(1);
    }, [rowPerPage, currentMap, middlewareSelected, statsDateFrom, statsDateTo])

    const loadRawProducts = (page: number) => {
        if(middlewareSelected && currentMap){
            dispatch(loadRawDataThrunk(middlewareSelected?.baseUrl, currentMap?.id, searchTerm, statsDateFrom, statsDateTo, page, rowPerPage));
        } 
    }


    return (
        <div>
             <PageHeader title={t("Données client")} middlePart={[{title:t("Picking"), to:ROUTES.PICKING_DASHBOARD}]}/>
            <div>
                <div className='table-component'>

                <DataTable 
                        className={loading ? "loading": ""}
                        dense
                        columns={headers} 
                        data={rawProducts?.items ?? []} 
                        progressPending={loading}
                        progressComponent={<Loading text={t("Chargement")}/>}
                        striped={true}
                        subHeader={true}
                        paginationPerPage={rowPerPage}
                        highlightOnHover={true}
                        pagination
                        paginationServer
                        paginationTotalRows={rawProducts?.totalCount ?? 0}
                        onChangePage={(page) => loadRawProducts(page)}
                        onChangeRowsPerPage={newPerPage => setRowPerPage(newPerPage)}
                        noDataComponent={<div className='w-100 text-center p-5'>{t("Aucune donnée disponible")}</div>}
                        subHeaderComponent={<> 
                            <h5 className='text-uppercase'> {t("Données brutes") + " (" + (rawProducts?.totalCount ?? 0) + ")"} {loading && <Loading inline size={20} />}</h5>
                            <div className='search-panel'>
                                <input placeholder={t("Mission")} onChange={(e) => setSearchTerm(e.target.value.toLowerCase())}/>
                            </div>
                            </>}/>
                </div>
            </div>

            {selectedRow && <Modal dialogClassName='modal-fno' show={true} onHide={() => setSelectedRow(undefined)}>
                <Modal.Header closeButton>
                    <Modal.Title>{t("Modifier la commande")}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <EditJsonRaw onCancel={() => setSelectedRow(undefined)} onSubmit={() => setSelectedRow(undefined)} value={selectedRow} loading={false}/>
                </Modal.Body>
            </Modal>}
        </div>
    )
}

export default MiddlewareRawData
