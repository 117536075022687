import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React from 'react'
import { useAppDispatch, useAppSelector } from 'store/store';
import SelectLarge from '../inputs/SelectLarge';
import { MiddlewareApiDetails } from 'backend/ApiBackofficeDefinition/data-contracts';
import { middlewarePickingActions } from 'store/reducers/MiddlewarePicking/middlewarePickingSlice';

/**
 * Middleware selector component
 * @returns 
 */
const MiddlewareSelector: React.FC = () => {
    const allMiddlewares = useAppSelector(state => state.middlewarePicking.middlewares);
    const middlewareSelected = useAppSelector(state => state.middlewarePicking.middlewareSelected);
    const dispatch = useAppDispatch();


    const onMiddlewareChange = (middleware: MiddlewareApiDetails) => {
        dispatch(middlewarePickingActions.setMiddlewareSelected(middleware));
    }


    if(allMiddlewares && allMiddlewares.length > 1)
    {
        return <SelectLarge options={allMiddlewares} 
                        isDisabled = {false}
                        value={middlewareSelected} 
                        onChange={onMiddlewareChange} 
                        valueRender={(middleware) =>{
                            const url = middleware.baseUrl.replaceAll("https://", "").replaceAll("http://", "");
                            return <div className='text-truncate text-uppercase'>
                            <div><FontAwesomeIcon className='me-2' icon={["fas", "network-wired"]} /> {url.length > 25 ? url.slice(0, 18) + "...": url}</div>
                            </div>
                        }}
                        optionRender={(middleware) => <div className='text-truncate text-uppercase'><FontAwesomeIcon className='me-2' icon={["fas", "network-wired"]} />  {middleware.baseUrl.replaceAll("https://", "")}</div>}/>
    }
    else 
    {
        return <></>
    }
}

export default MiddlewareSelector