import i18next from "i18next";
import { toast } from "react-toastify";
import {middlewarePickingActions} from '../middlewarePickingSlice';
import { AppThunk } from "store/store"
import apiMiddlewareDefinition from "backend/apiMiddlewareDefinition";
import _ from "lodash";
import dayjs from "dayjs";


export const loadStatisticsThrunk = (
        baseUrl: string, 
        mapId: string, 
        from: Date, 
        to: Date): AppThunk => {
    return async (dispatch, getState) => {
      try {
        
        const tenant = getState().userProfil.currentTenant?.tenant
        dispatch(middlewarePickingActions.setLoadingList(true));

        //remove timeoffset
        const fromParam = dayjs(from).add(-from.getTimezoneOffset() ,"minutes").toISOString();
        const toParam = dayjs(to).add(-to.getTimezoneOffset() ,"minutes").toISOString();

        const requests = await apiMiddlewareDefinition(baseUrl, tenant ?? "")
                                  .statistics.getGlobalStatistics(mapId,{
                                    from: fromParam,
                                    to: toParam
                                  });
                                  

        dispatch(middlewarePickingActions.setGlobalStatisticsByDay(requests.data));



      } catch (error: any) {

        if (!error.status || error.status != 401) {
          toast.error(i18next.t("Erreur lors du chargement des requêtes d'optimisation"));
        }
      } finally {
        // Set loading state back to false after the operation completes
        dispatch(middlewarePickingActions.setLoadingList(false));
      }
    };
  };