import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { ClientDetails, MiddlewareApiDetails } from "backend/ApiAdminDefinition/data-contracts";
import { ClientItem, MapDetails } from "backend/ApiBackofficeDefinition/data-contracts";
import { BatchingRawFileDetails, BatchingRawFileDetailsPagedListResult, PathDetailsModelPagedListResult, RequestClientDetails, RequestClientDetailsPagedListResult, RequestRawProductDetailsPagedListResult, StatisticsByDay } from "backend/ApiMiddlewareDefinition/data-contracts";
import dayjs from "dayjs";
import { userProfilActions } from "store/reducers/Profil/userProfilSlice";

export interface IMiddlewarePickingStore {
  middlewares: MiddlewareApiDetails[];
  middlewareSelected: MiddlewareApiDetails | undefined;
  globalStatisticsByDay: StatisticsByDay[];
  loadingList: boolean;
  loadingForm: boolean;
  statsDateFrom: string;
  statsDateTo: string;

  missions: PathDetailsModelPagedListResult | undefined;
  rawProducts: RequestRawProductDetailsPagedListResult | undefined;
  batchingFiles: BatchingRawFileDetailsPagedListResult | undefined;

}

const initialState: IMiddlewarePickingStore = {
  middlewareSelected: undefined,
  middlewares:[],
  globalStatisticsByDay: [],
  loadingList: false,
  loadingForm: false,
  statsDateFrom: dayjs().add(-6, 'day').toISOString(),
  statsDateTo: dayjs().toISOString(),

  missions: undefined,
  rawProducts: undefined,
  batchingFiles: undefined
};

const middlewarePickingSlice = createSlice({
  name: "middlewareRequestSlice",
  initialState,
  reducers: {
    setMiddlewares: (state, action: PayloadAction<MiddlewareApiDetails[]>) => {
      state.middlewares = action.payload;
    },
    setMiddlewareSelected: (state, action: PayloadAction<MiddlewareApiDetails | undefined>) => {
      state.middlewareSelected = action.payload;
    },
    setGlobalStatisticsByDay: (state, action: PayloadAction<StatisticsByDay[]>) => {
      state.globalStatisticsByDay = action.payload;
    },
    setLoadingList: (state, action: PayloadAction<boolean>) => {
      state.loadingList = action.payload;
    },
    setLoadingForm: (state, action: PayloadAction<boolean>) => {
      state.loadingForm = action.payload;
    },
    setStatsDates: (state, action: PayloadAction<{from: Date, to: Date}>) => {
      state.statsDateFrom = action.payload.from.toISOString();
      state.statsDateTo = action.payload.to.toISOString();
    },
    setMissions: (state, action: PayloadAction<PathDetailsModelPagedListResult | undefined>) => {
      state.missions = action.payload;
    },
    setRawProducts: (state, action: PayloadAction<RequestRawProductDetailsPagedListResult | undefined>) => {
      state.rawProducts = action.payload;
    },
    setBatchingRawFiles: (state, action: PayloadAction<BatchingRawFileDetailsPagedListResult | undefined>) => {
      state.batchingFiles = action.payload;
    },
    updateBatchingRawFile: (state, action: PayloadAction<BatchingRawFileDetails>) => {
      if (state.batchingFiles) {
        state.batchingFiles = {
          ...state.batchingFiles,
          items: state.batchingFiles.items.map((item) => {
            if (item.id === action.payload.id) {
              return action.payload;
            } else {
              return item;
            }
          })
        };
      }
    }
  },
  extraReducers: (builder) => {
    // Handle the case when the user logs out
    builder.addCase(userProfilActions.setLogout, (state, action) => {
      //state.middlewareSelected = undefined;
      //state.middlewares = [];
      //state.loading = false;

      state = initialState;
    });
  },
});

// Export the actions and reducer from the admin client map asset slice
export const {
  actions: middlewarePickingActions,
  reducer: middlewarePickingReducer,
} = middlewarePickingSlice;
