import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next';
import PageHeader from 'components/PageHeader';
import { useAppDispatch, useAppSelector } from 'store/store'
import nameOf from 'utility/nameOf';
import { useLocation, useNavigate } from 'react-router-dom';
import { ROUTES } from 'resources/routes-constants';
import { loadStatisticsThrunk } from 'store/reducers/MiddlewarePicking/thrunks/loadStatisticsThrunk';
import dayjs from 'dayjs';
import { Col, Offcanvas, Row } from 'react-bootstrap';
import StatisticCard from 'components/StatisticCard';
import _ from 'lodash';
import LineChart from 'components/Charts/LineChart';
import IDatasetModel from 'models/Chartjs/IDatasetModel';
import BarChart from 'components/Charts/BarChart';
import DoughnutChart from 'components/Charts/DoughnutChart';
import IDatasetDoughnutModel from 'models/Chartjs/IDatasetDoughnutModel';
import { Chart } from 'react-chartjs-2';
import DataTable from 'react-data-table-component';
import Loading from 'components/Loading';
import ButtonFno from 'components/inputs/ButtonFno';

const MiddlewareStatisticsPicking: React.FC = () => {
    const { t } = useTranslation(nameOf({MiddlewareStatisticsPicking}), { useSuspense: false});
    const dispatch = useAppDispatch();
    const currentUser = useAppSelector(state => state.userProfil.currentUser);
    const mapSelected = useAppSelector(state => state.map.currentMap);
    const {
        middlewareSelected,
        globalStatisticsByDay,
        loadingList: loading,
        statsDateFrom,
        statsDateTo
    } = useAppSelector(state => state.middlewarePicking);
    const location = useLocation();
    const navigate = useNavigate();

    const [showUnknownPage, setShowUnknownPage] = useState(false);
    const [showPickerDetailsPage, setShowPickerDetailsPage] = useState(false);

    useEffect(() => {
        document.title = t('Statistiques picking');
    }, [])

    useEffect(() => {
        if(!middlewareSelected)
        {
            navigate(ROUTES.DASHBOARD)
        }
        else{
            loadStatistics();
        }
    }, [middlewareSelected])



    useEffect(() => {
        loadStatistics();
    }, [statsDateFrom, statsDateTo])


    const loadStatistics = () => {
        if(middlewareSelected && mapSelected)
        {
            dispatch(loadStatisticsThrunk(middlewareSelected?.baseUrl, mapSelected?.id, dayjs(statsDateFrom).toDate(), dayjs(statsDateTo).toDate()));
        }
        
    }


    const labels = globalStatisticsByDay.map(stat => dayjs(stat.date).format("DD/MM") ?? "");

    const datasetRequests = {
        data: globalStatisticsByDay.map(stat => stat.totalRequests ?? 0),
        label: t("Requêtes total"),
        color: "#21d8de",
    } as IDatasetModel;

    const datasetRequestsOptimized = {
        data: globalStatisticsByDay.map(stat => stat.totalRequestOptimized ?? 0),
        label: t("Requêtes optimisées"),
        color: "#198754",
    } as IDatasetModel;

    const datasetErrors = {
        data: globalStatisticsByDay.map(stat => stat.totalRequestErrors ?? 0),
        label: t("Erreurs"),
        color: "#dc3545",
    } as IDatasetModel;

    const datasetProducts = {
        data: globalStatisticsByDay.map(stat => stat.totalProducts ?? 0),
        label: t("Produits"),
        color: '#ff6d3a',
        yAxisID:"products"
    } as IDatasetModel;

    const datasetMissions = {
        data: globalStatisticsByDay.map(stat => stat.totalMissions ?? 0),
        label: t("Missions"),
        color: '#198754',
        type:"bar",
        yAxisID:"missions"
    } as IDatasetModel;




    const datasetDistanceOptimized = {
        data: globalStatisticsByDay.map(stat => stat.totalOptimizedDistance ?? 0),
        label: t("Distance optimisée"),
        color: '#198754',
    } as IDatasetModel;
    

    const datasetDistanceOriginal = {
        data: globalStatisticsByDay.map(stat => stat.totalOriginalDistance ?? 0),
        label: t("Distance original"),
        color: '#ff6d3a',
    } as IDatasetModel;


    const datasetNbPicker = {
        data: globalStatisticsByDay.map(stat => Object.entries(stat.pickerTotal ?? {}).length),
        label: t("Nombre de pickers"),
        color: '#198754',
    } as IDatasetModel;

    console.log("pickers", globalStatisticsByDay.map(stat => Object.entries(stat.pickerTotal ?? {}).length));



    const totalRequests = _.sumBy(globalStatisticsByDay, 'totalRequests') ?? 0;
    const totalRequestsOptimized = _.sumBy(globalStatisticsByDay, 'totalRequestOptimized') ?? 0;

    const totalProducts = _.sumBy(globalStatisticsByDay, 'totalProducts') ?? 0;
    const totalQuantity = Math.trunc(_.sumBy(globalStatisticsByDay, 'totalQuantity') ?? 0);
    const totalMissions = _.sumBy(globalStatisticsByDay, 'totalMissions') ?? 0;
    const totalRequestErrors = _.sumBy(globalStatisticsByDay, 'totalRequestErrors') ?? 0;
    const totalDaysPeriode = dayjs(statsDateTo).diff(dayjs(statsDateFrom), 'day') + 1;

    const totalOriginalDistancePeriod = _.sumBy(globalStatisticsByDay, 'totalOriginalDistance') ?? 0;
    const totalOptimizedDistancePeriod = _.sumBy(globalStatisticsByDay, 'totalOptimizedDistance') ?? 0;
    const totalDistanceGain = (totalOriginalDistancePeriod - totalOptimizedDistancePeriod) / 1000;

    const gainpourcent = -((totalOptimizedDistancePeriod - totalOriginalDistancePeriod) / totalOriginalDistancePeriod) * 100;
    const gainPourcentString = gainpourcent.toFixed(2);
    
    const totalUnknowAddress = globalStatisticsByDay
        .map(stat => stat.unknowAddressTotal)
        .reduce((acc, record) => {
            if(record && acc)
            {
                Object.entries(record).forEach(([key, value]) => {
                    if (acc[key]) {
                        acc[key] += value;
                    } else {
                        acc[key] = value;
                    }
                });
            }
           
            return acc;
        }, {} as Record<string, number>);

        const totalPickerDetails = globalStatisticsByDay
        .map(stat => stat.pickerTotal)
        .reduce((acc, record) => {
            if(record && acc)
            {
                Object.entries(record).forEach(([key, value]) => {
                    if (acc[key]) {
                        acc[key] += value;
                    } else {
                        acc[key] = value;
                    }
                });
            }
           
            return acc;
        }, {} as Record<string, number>);


    const datasetRequestsDoughnut = {
        data: [
            totalRequestsOptimized,
            totalRequests - totalRequestsOptimized,
            totalRequestErrors
        ],
        labels: [
            t("Optimisées"),
            t("Non optimisées"),
            t("Erreurs")
        ],
        backgroundColor: [
            '#198754',
            '#ff6d3a',
            '#dc3545'
        ],
        showPourcentage: true
    } as IDatasetDoughnutModel;


    return (
        <div>
             <PageHeader title={t("Statistiques")} middlePart={[{ to: ROUTES.DASHBOARD, title: t('Picking')}]}/>
            <div>
                {<Row className='justify-content-evenly'>
                    {totalUnknowAddress && Object.entries(totalUnknowAddress).length > 0 && 
                        <Col className='mb-3'><StatisticCard title={t("Adresses inconnues*")} onClick={() => setShowUnknownPage(true)} color='red' value={`${Object.entries(totalUnknowAddress).length}`} icon='warning' loading={loading}/></Col>}
                    {!!gainpourcent &&  
                        <Col className='mb-3'><StatisticCard title={t("Gain*")} color='blue' value={`${gainPourcentString}%`} icon='chart-line' loading={loading}/></Col>}
                    <Col className='mb-3'><StatisticCard title={t("Distance économisées*")} color='orange' value={`${totalDistanceGain.toFixed(2)} km`} icon='person-walking' loading={loading}/></Col>
                    {totalPickerDetails && Object.entries(totalPickerDetails).length > 0 && 
                        <Col className='mb-3'><StatisticCard title={t("Total pickers*")} onClick={() => setShowPickerDetailsPage(true)} color='green' value={`${Object.entries(totalPickerDetails).length}`} icon='users' loading={loading}/></Col>}

                    <Col className='mb-3'><StatisticCard title={t("Total jours*")} color='yellow' value={totalDaysPeriode.toLocaleString("fr-FR")} icon='calendar-days' loading={loading}/></Col>
                </Row>}
                <div>
                    <div className='float-end'>
                        <small>{"* Sur la période du "+dayjs(statsDateFrom).format("DD/MM/YYYY")+" au "+dayjs(statsDateTo).format("DD/MM/YYYY")}</small>
                    </div>
                </div>
            </div>
            <div className="clearfix"></div>
            <Row>
                <Col>
                    <div className='table-component'>
                        <header className='section-header'><h5 className='text-uppercase'>{t("Requêtes")}</h5></header>
                        <Row>
                            <Col sm="4">
                                <Row className='justify-content-between p-1'>
                                    <Col xs="6" className='mb-3'><StatisticCard title={t("Total requêtes")} color='blue' value={`${totalRequests.toLocaleString("fr-FR")}`} icon='server' loading={loading}/></Col>
                                    <Col xs="6" className='mb-3'><StatisticCard title={t("Total erreurs")} color='red' value={totalRequestErrors} icon='triangle-exclamation' loading={loading}/></Col>
                                    <Col xs="6" className='mb-3'><StatisticCard title={t("Optimisées")} color='green' value={`${totalRequestsOptimized.toLocaleString("fr-FR")}`} icon='bolt' loading={loading}/></Col>
                                    <Col xs="6" className='mb-3'><StatisticCard title={t("Non optimisées")} color='orange' value={`${(totalRequests - totalRequestsOptimized).toLocaleString("fr-FR")}`} icon='equals' loading={loading}/></Col>
                                </Row>
                            </Col>
                            <Col>
                                <DoughnutChart dataset={datasetRequestsDoughnut}/>
                            </Col>
                        </Row>
                        
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className='table-component'>
                        <header className='section-header'><h5 className='text-uppercase'>{t("Distance originale VS optimisée (mètres)")}</h5></header>
                        <BarChart labels={labels} datasets={[datasetDistanceOptimized, datasetDistanceOriginal]}/>
                    </div>
                </Col>
            </Row>


            <Row>
                <Col>
                    <div className='table-component'>
                        <header className='section-header'><h5 className='text-uppercase'>{t("Requêtes traités par jour")}</h5></header>
                        <BarChart labels={labels} datasets={[datasetRequests, datasetRequestsOptimized, datasetErrors]}/>
                    </div>
                </Col>
            </Row>

            <Row>
                <Col>
                    <div className='table-component'>
                        <header className='section-header'><h5 className='text-uppercase'>{t("Nombre de pickers par jour")}</h5></header>
                        <LineChart labels={labels} datasets={[datasetNbPicker]}/>
                    </div>
                </Col>
            </Row>
            
            <Row>
                <Col>
                    <div className='table-component'>
                        <header className='section-header'><h5 className='text-uppercase'>{t("Missions et produits traités par jour")}</h5></header>
                        <Row>
                            <Col sm="12" lg="3">
                                <Row className='justify-content-between'>
                                    <Col xs="12" md="6" lg="12" className='mb-3'><StatisticCard title={t("Produits collectés")} color='blue' value={totalQuantity.toLocaleString("fr-FR")} icon='tags' loading={loading}/></Col>
                                    <Col xs="12" md="6" lg="12" className='mb-3'><StatisticCard title={t("Total SKUs")} color='orange' value={totalProducts.toLocaleString("fr-FR")} icon='barcode' loading={loading}/></Col>
                                    <Col xs="12" md="6" lg="12" className='mb-3'><StatisticCard title={t("Total missions")} color='green' value={totalMissions.toLocaleString("fr-FR")} icon='box' loading={loading}/></Col>
                                </Row>
                            </Col>
                            <Col lg="9">
                                <div  style={{height: '400px'}}>
                                    <Chart  type='bar' 
                                    options={{
                                        responsive: true,
                                        maintainAspectRatio: false,
                                        scales: {
                                            products: {
                                                type: 'linear',
                                                display: true,
                                                position: 'left',
                                            },
                                            missions: {
                                                type: 'linear',
                                                display: true,
                                                position: 'right',
                                        
                                                // grid line settings
                                                grid: {
                                                drawOnChartArea: false, // only want the grid lines for one axis to show up
                                                },
                                            },
                                        },
                                        plugins: {
                                            datalabels:{
                                                formatter: (value, ctx) => {
                                                  return "";
                                                },
                                                color: '#fff',
                                              },
                                        }
                                    }}
                                    data={{
                                        labels,
                                        datasets: [
                                            {
                                                type: 'line' as const,
                                                label: 'Produits',
                                                backgroundColor: datasetProducts.color,
                                                borderColor: datasetProducts.color,
                                                borderWidth: 5,
                                                pointRadius: 3,
                                                pointHoverRadius: 4,
                                                pointHoverBorderColor: datasetProducts.color,
                                                pointHoverBackgroundColor: datasetProducts.color,
                                                pointHitRadius: 20,
                                                fill: false,
                                                data: datasetProducts.data,
                                                yAxisID: 'products',
                                            },
                                            {
                                                type: 'bar' as const,
                                                label: 'Missions',
                                                backgroundColor: datasetMissions.color,
                                                data: datasetMissions.data,
                                                borderColor: datasetMissions.color,
                                                borderWidth: 5,
                                                yAxisID: 'missions',
                                            },
                                        ]
                                    }} />
                                </div>
                            </Col>
                        </Row>
                        
                    </div>
                </Col>
            </Row>

            <Offcanvas className="offcanvas-request-details" show={showUnknownPage} onHide={() => setShowUnknownPage(false)} >
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("Liste des adresses inconnues")}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <DataTable 
                        className={loading ? "loading": ""}
                        dense
                        columns={[{
                            id:"key",
                            name: t("Emplacement"),
                            sortable: true,
                            selector: row => row.key
                        },
                        {
                            id:"value",
                            name: t("Nb occurences"),
                            sortable: true,
                            selector: row => row.value
                        }]} 
                        data={totalUnknowAddress ? Object.entries(totalUnknowAddress).map(([key, value]) => { return {key, value}}): []} 
                        progressPending={loading}
                        progressComponent={<Loading text={t("Chargement")}/>}
                        striped={true}
                        subHeader={true}
                        highlightOnHover={true}
                        pagination
                        noDataComponent={<div className='w-100 text-center p-5'>{t("Aucun emplacement inconnu")}</div>}/>
                </Offcanvas.Body>
        </Offcanvas>

        <Offcanvas className="offcanvas-request-details" show={showPickerDetailsPage} onHide={() => setShowPickerDetailsPage(false)} >
                <Offcanvas.Header closeButton>
                <Offcanvas.Title>{t("Liste des pickers sur la période")}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <DataTable 
                        className={loading ? "loading": ""}
                        dense
                        columns={[{
                            id:"key",
                            name: t("Picker"),
                            sortable: true,
                            selector: row => row.key
                        },
                        {
                            id:"value",
                            name: t("Nb commandes"),
                            sortable: true,
                            selector: row => row.value
                        }]} 
                        data={totalPickerDetails ? Object.entries(totalPickerDetails).map(([key, value]) => { return {key, value}}): []} 
                        progressPending={loading}
                        progressComponent={<Loading text={t("Chargement")}/>}
                        striped={true}
                        subHeader={true}
                        highlightOnHover={true}
                        noDataComponent={<div className='w-100 text-center p-5'>{t("Aucun picker")}</div>}/>
                </Offcanvas.Body>
        </Offcanvas>

        </div>
    )
}

export default MiddlewareStatisticsPicking
