import i18next from "i18next";
import { toast } from "react-toastify";
import {middlewarePickingActions} from '../middlewarePickingSlice';
import { AppThunk } from "store/store"
import apiMiddlewareDefinition from "backend/apiMiddlewareDefinition";
import _ from "lodash";
import dayjs from "dayjs";
import { PathDetailsBatchField } from "backend/ApiMiddlewareDefinition/data-contracts";


export const loadBatchingRawFileThrunk = (
        baseUrl: string, 
        mapId: string, 
        page: number,
        pageSize = 10,
        setLoading = true,
        sortColumn = "dateCreated",
        sortDirection = "desc"): AppThunk => {
    return async (dispatch, getState) => {
      try {
        
        const tenant = getState().userProfil.currentTenant?.tenant
        if(setLoading)
        {
          dispatch(middlewarePickingActions.setLoadingList(true));
        }

        //remove timeoffset
        //const fromParam = dayjs(from).add(-from.getTimezoneOffset() ,"minutes").toISOString();
        //const toParam = dayjs(to).add(-to.getTimezoneOffset() ,"minutes").add(1, "day").toISOString();

        const batchingFileResult = await apiMiddlewareDefinition(baseUrl, tenant ?? "")
                                  .batching.searchBatchingRawFiles(mapId, {
                                    mapId: mapId,
                                    pageSize: pageSize,
                                    page: page,
                                    
                                  });

       dispatch(middlewarePickingActions.setBatchingRawFiles(batchingFileResult.data));

      } catch (error: any) {

        if (!error.status || error.status != 401) {
          toast.error(i18next.t("Erreur lors du chargement des fichiers de batching"));
          dispatch(middlewarePickingActions.setBatchingRawFiles(undefined));
        }
      } finally {
        // Set loading state back to false after the operation completes
        if(setLoading)
        {
          dispatch(middlewarePickingActions.setLoadingList(false));
        }
       
      }
    };
  };