import { userProfilActions} from "store/reducers/Profil/userProfilSlice";
import { AppThunk } from "store/store"
import apiBackofficeDefinition from "backend/apiBackofficeDefinition";
import { middlewarePickingActions } from "store/reducers/MiddlewarePicking/middlewarePickingSlice";


/**
 * This is used to initialize the middleware for the current tenant.
 * If the current middleware is not available in the list of middlewares, the first one is selected by default.
 * If the list of middlewares is empty, the current middleware is reset to undefined.
 * @returns 
 */
export const loadMiddlewareThunk = (): AppThunk => {
    return async (dispatch, getState) => {
        try {
            // get all middlewares for the current tenant
            const middlewares = await apiBackofficeDefinition.middleware.getAllClientMiddlewares();

            dispatch(middlewarePickingActions.setMiddlewares(middlewares.data));

            // get the current middleware id
            const currentMiddlewareId = getState().middlewarePicking.middlewareSelected?.id;
            
            // if the current middleware is in the list of middlewares, select it
            if (currentMiddlewareId && middlewares.data.some((m) => m.id === currentMiddlewareId)) {
                dispatch(middlewarePickingActions.setMiddlewareSelected(middlewares.data.find((m) => m.id === currentMiddlewareId)));
            } else if (middlewares.data.length > 0) {
                // if the list of middlewares is not empty, select the first one
                dispatch(middlewarePickingActions.setMiddlewareSelected(middlewares.data[0]));
            } else {
                // if the list of middlewares is empty, reset the current middleware to undefined
                dispatch(middlewarePickingActions.setMiddlewareSelected(undefined));
            }
        } catch (error) {
            console.error(error);
            dispatch(userProfilActions.setLoadingUserData(false));
        }
    }
}
